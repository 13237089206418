import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  skillRating: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  skillRatingLabel: {
    minWidth: 120
  },
  rating: {
    marginLeft: 16
  },
  avatarBig: {
    width: 200,
    height: 200
  },
  avatarSmall: {
    width: 50,
    height: 50,
    float: 'right'
  },
  avatarTech: {
    width: 86,
    height: 43,
    paddingRight: 16
  },
  slide: {
    position: 'fixed',
    right: 10,
    bottom: '50%',
    minWidth: 275,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  flatInfo: {
    border: 'none',
    boxShadow: 'none',
    margin: 10
  },
  flat: {
    border: 'none',
    boxShadow: 'none',
    margin: 10
  },
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

function English() {
  const classes = useStyles();
  const trigger = useScrollTrigger({ threshold: 200, disableHysteresis: true });
  const [showMore, setShowMore] = React.useState(false);

  const infoCard = (slided) => (
    <Card className={slided ? classes.root : classes.flatInfo}>
      <CardContent>
        <Box display="flex">
          <Box flexGrow={1}>
            <Box height={slided ? 50 : 100}>
              <Typography variant={slided ? 'h4' : 'h3'} style={{ float: 'left' }}>
                Amaury BES
              </Typography>
            </Box>
            <Typography variant="h5">
              UI team leader
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Communication, Adaptation, Analysis
            </Typography>
            <Typography variant="body2" component="p">
              13 years of experience
              <br />
              UI / UX specialist
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Avatar
              alt="Amaury BES"
              src="face.jpg"
              className={slided ? classes.avatarSmall : classes.avatarBig}
            />
          </Box>
        </Box>
        {showMore && !slided && (
        <Box mt={2}>
          <Divider variant="middle" />
          <br />
          <Link
            href="mailto:amaury.bes@gmail.com"
          >
            <Typography variant="body2">
              amaury.bes@gmail.com
            </Typography>
          </Link>
          <Link
            href="tel:+33678485698"
          >
            <Typography variant="body2">
              Phone : +33678485698
            </Typography>
          </Link>
          <Typography variant="body2" component="p" mt={2}>
            1 rue de Vallières 74800 Amancy FRANCE
            <br />
            Birth date : 05/05/1986
            <br />
            Nationality : French
          </Typography>
        </Box>
        )}
      </CardContent>
      {!slided
      && (
      <CardActions>
        {!showMore && <Button size="small" onClick={() => { setShowMore(true); }}>Show more</Button>}
        {showMore && <Button size="small" onClick={() => { setShowMore(false); }}>Show less</Button>}
      </CardActions>
      )}
    </Card>
  );

  return (
    <Container maxWidth="lg" style={{ paddingTop: 16, paddingBottom: 16 }}>
      <Slide in={trigger} className={classes.slide}>
        {infoCard(true)}
      </Slide>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }}>
        {infoCard(false)}
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                About me
              </Typography>
            </Box>
            <Typography variant="subtitle1" align="justify" component="p">
              I am a seasoned project and team leader with 13 years of experience
              in a financial solutions company. I began my journey as a full-stack
              developer but, fueled by my passion for user interfaces and user
              experience, I transitioned to front-end development. I currently hold
              the position of UI team leader, which entails overseeing all UI-related
              projects and team members within my department.
              <br />
              <br />
              My main attributes are communication, adaptation, analysis capabilities and
              my deductive skills.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Employment history
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
              Bottomline Technologies
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    UI Team leader
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2020 - Present
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Lead UI projects from planning to production</li>
                  <li>Manage an international team for a SAAS platform</li>
                  <li>Design  and implements highly technical solutions</li>
                  <li>Write agile epics and user stories</li>
                  <li>Estimate load and plan resources</li>
                  <li>Collaborate with product manager and UX designer for timely delivery</li>
                  <li>Design a micro front-end ecosystem using single-spa</li>
                  <li>Align strategies with other UI team leaders</li>
                  <li>Implement and maintain CI/CD processes</li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Front end developer
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2016 - 2020
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Develop screens on different projects using internal and
                    third-party framework
                  </li>
                  <li>Take charge in the full development process, from UX design to delivery</li>
                  <li>Self-train on new framework and constantly push toward innovation</li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Full stack developer
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2010 - 2016
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Took an active role in the full development life cycle</li>
                  <li>Help Product Owner to design new features and to make design-to-cost</li>
                  <li>Develop all parts of the software (Java EE UI, Database, C server...)</li>
                  <li>Propose proactively new UX interactions or enhancing existing ones</li>
                  <li>Involve in code reliability and maintainability</li>
                  <li>Understand user needs and how to solve effectively problems</li>
                  <li>Search and push forward new technologies and expose their benefits</li>
                  <li>Train many new joiners to the team</li>
                  <li>
                    Learn and use agile scrum methodology mainly as developer and
                    as backup for Scrum Master, Product Owner and QA roles
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Box mt={3} />
            <Typography variant="h5" gutterBottom>
              Logica
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Intern developer
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2010
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Develop application in .NET
                  </li>
                  <li>Generate screens UI according to the specification</li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Technical skills
              </Typography>
            </Box>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="Front End"
                    variant="square"
                    src="web-tech.png"
                    style={{ width: 86 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Front End"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      REACT js / Typescript / single-spa / systemJS / Backbone / Marionette
                      / Handlebars / Require / D3.js
                    </Typography>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="Back End"
                    variant="square"
                    src="java.png"
                    style={{ width: 86 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Back End"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Java EE / Spring / JSF / Hibernate / Docker / PLSQL
                    </Typography>
                  )}
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="DevOps"
                    variant="square"
                    src="DevOpsLoop.png"
                    style={{ width: 86 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="DevOps"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Kubernetes / argo-cd / Docker / Jenkins
                    </Typography>
                    )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="Project management"
                    variant="square"
                    src="project-mgmt.png"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Project Management"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Agile scrum / Git / Gitlab / jenkins / Atlassian suite / Miro / Lucid apps
                    </Typography>
                  )}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Personal skills
              </Typography>
            </Box>
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Analysis</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Synthesis</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Communication</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Productivity</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Adaptation</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Leadership</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Organisation</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Initiative</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Efficiency</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Creativity</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>French</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>English</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Education
              </Typography>
            </Box>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="2007 – 2010 Polytech’ Grenoble"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Engineer diploma in Computer Networks and Multimedia Communication.
                      <br />
                      Java EE, UX/UI, Database, computer science basics…
                    </Typography>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="2005 – 2007 Network DUT"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      University Diploma in Technology
                    </Typography>
                  )}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Interests
              </Typography>
            </Box>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Spending time with my wife and kids"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Sports"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Work out, hiking, various other sports
                    </Typography>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Hobbies"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Board games, video games, escape games, chess, manga, TV shows
                      <br />
                      Learn new things, general knowledge, history...
                    </Typography>
                  )}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default English;

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  skillRating: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  skillRatingLabel: {
    minWidth: 120
  },
  rating: {
    marginLeft: 16
  },
  avatarBig: {
    width: 200,
    height: 200
  },
  avatarSmall: {
    width: 50,
    height: 50,
    float: 'right'
  },
  avatarTech: {
    width: 86,
    height: 43,
    paddingRight: 16
  },
  slide: {
    position: 'fixed',
    right: 10,
    bottom: '50%',
    minWidth: 275,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  flatInfo: {
    border: 'none',
    boxShadow: 'none',
    margin: 10
  },
  flat: {
    border: 'none',
    boxShadow: 'none',
    margin: 10
  },
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

function French() {
  const classes = useStyles();
  const trigger = useScrollTrigger({ threshold: 150, disableHysteresis: true });
  const [showMore, setShowMore] = React.useState(false);

  const infoCard = (slided) => (
    <Card className={slided ? classes.root : classes.flatInfo}>
      <CardContent>
        <Box display="flex">
          <Box flexGrow={1}>
            <Box height={slided ? 50 : 100}>
              <Typography variant={slided ? 'h4' : 'h3'} style={{ float: 'left' }}>
                Amaury BES
              </Typography>
            </Box>
            <Typography variant="h5">
              Chef d&apos;équipe UI
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Communication, Adaptabilité, Analyse
            </Typography>
            <Typography variant="body2" component="p">
              13 années d&apos;expérience
              <br />
              Spécialiste UI/UX
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Avatar
              alt="Amaury BES"
              src="face.jpg"
              className={slided ? classes.avatarSmall : classes.avatarBig}
            />
          </Box>
        </Box>
        {showMore && !slided && (
          <Box mt={2}>
            <Divider variant="middle" />
            <br />
            <Link
              href="mailto:amaury.bes@gmail.com"
            >
              <Typography variant="body2">
                amaury.bes@gmail.com
              </Typography>
            </Link>
            <Link
              href="tel:+33678485698"
            >
              <Typography variant="body2">
                Téléphone : +33678485698
              </Typography>
            </Link>
            <Typography variant="body2" component="p" mt={2}>
              1 rue de Vallières 74800 Amancy FRANCE
              <br />
              Date de naissance : 05/05/1986
              <br />
              Nationalité : Française
            </Typography>
          </Box>
        )}
      </CardContent>
      {!slided
      && (
        <CardActions>
          {!showMore && <Button size="small" onClick={() => { setShowMore(true); }}>Voir plus</Button>}
          {showMore && <Button size="small" onClick={() => { setShowMore(false); }}>Voir moins</Button>}
        </CardActions>
      )}
    </Card>
  );

  return (
    <Container maxWidth="lg" style={{ paddingTop: 16, paddingBottom: 16 }}>
      <Slide in={trigger} className={classes.slide}>
        {infoCard(true)}
      </Slide>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }}>
        {infoCard(false)}
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Profil
              </Typography>
            </Box>
            <Typography variant="subtitle1" align="justify" component="p">
              Je suis un chef de projet et d&apos;équipe UI avec 13 années d&apos;expérience au sein
              d&apos;une entreprise spécialisée dans les solutions financières. Mon parcours a
              débuté en tant que développeur full-stack, mais ma passion pour les interactions
              utilisateur et les interfaces intuitives m&apos;a rapidement conduit vers
              le développement
              UI. Au fil du temps, j&apos;ai évolué pour devenir Chef de projet UI,
              ce qui m&apos;a confié
              la responsabilité de la gestion complète des projets ainsi que des développeurs
              UI au sein de mon équipe.
              <br />
              <br />
              Mes qualités principales sont la communication, l’adaptation, l’analyse ainsi que
              l’esprit de synthèse.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Historique des postes occupés
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
              Bottomline Technologies
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Chef de projet UI
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2020 - Aujourd&apos;hui
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Diriger un projet UI du planning/design jusqu&apos;à la mise en
                    production
                  </li>
                  <li>
                    Diriger une équipe internationale de développeur sur
                    des projet UI sur une plateforme
                    SAAS
                  </li>
                  <li>Concevoir et mettre en œuvre des solutions très techniques</li>
                  <li>Écrire des user stories et des epics</li>
                  <li>Estimer la charge et planifier les ressources</li>
                  <li>
                    Collaborer avec les manager de produits et les designers pour livrer en
                    temps et en heure la meilleure expérience utilisateur possible
                  </li>
                  <li>
                    Designer et développer un écosystème de micro front end base sur la
                    technologie single-spa pour être capable de consommer et de partager des MFEs
                    d&apos;autres départements.
                  </li>
                  <li>
                    Aligner les stratégies avec les chefs de projet UI des autres départements
                  </li>
                  <li>
                    Mettre en place et maintenir les process de CI/CD
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Développeur front end
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2016 - 2020
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Développer des écrans sur diffèrent projets en utilisant des Framework
                    interne ou externe
                  </li>
                  <li>
                    Prendre en charge le processus de développement du début à la fin,
                    du design à la production
                  </li>
                  <li>
                    S&apos;auto former sur les nouveaux Framework de développement UI et pousser à
                    l&apos;innovation
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Développeur full stack
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2010 - 2016
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Prendre un rôle actif dans le cycle de développement complet</li>
                  <li>
                    Aider à designer les Nouvelles fonctionnalités et faire du
                    “design-to-cost”
                  </li>
                  <li>
                    Développer toutes les parties du logiciel (Java EE, UI, Database, C server ...)
                  </li>
                  <li>Être force de proposition pour l’innovation et l’expérience utilisateur</li>
                  <li>Être impliquer dans la qualité code et la maintenabilité</li>
                  <li>
                    Comprendre les besoin utilisateur et comment résoudre
                    efficacement leurs problématiques
                  </li>
                  <li>
                    Chercher et pousser vers les nouvelles technologies et montrer leurs bénéfices
                  </li>
                  <li>Former les nouveaux développeurs</li>
                  <li>
                    Utiliser les méthodologies agiles en endossant différents rôles (SM, PO, QA...)
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Box mt={3} />
            <Typography variant="h5" gutterBottom>
              Logica
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box style={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    style={{ float: 'left' }}
                  >
                    Développeur stagiaire
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ float: 'right' }}
                  >
                    2010
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Développer des applications en .NET
                  </li>
                  <li>Générer des écrans en suivant les spécifications client</li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Compétences Techniques
              </Typography>
            </Box>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="Front End"
                    variant="square"
                    src="web-tech.png"
                    style={{ width: 86 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Front End"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      REACT js / Typescript / single-spa / systemJS / Backbone / Marionette
                      / Handlebars / Require / D3.js
                    </Typography>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="Back End"
                    variant="square"
                    src="java.png"
                    style={{ width: 86 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Back End"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Java EE / Spring / JSF / Hibernate / PLSQL
                    </Typography>
                  )}
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="DevOps"
                    variant="square"
                    src="DevOpsLoop.png"
                    style={{ width: 86 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="DevOps"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Kubernetes / argo-cd / Docker / Jenkins
                    </Typography>
                    )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarTech}>
                  <Avatar
                    alt="Gestion de projet"
                    variant="square"
                    src="project-mgmt.png"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Gestion de projet"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Agile scrum / Git / Gitlab / Atlassian suite / Miro / Lucid apps
                    </Typography>
                  )}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Compétences Personnelles
              </Typography>
            </Box>
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Analyse</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Synthèse</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Communication</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Productivité</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Adaptabilité</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Leadership</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Organisation</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Initiative</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Efficacité</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Créativité</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Français</Typography>
                <Rating value={5} readOnly className={classes.rating} />
              </Box>
              <Box m={2} className={classes.skillRating}>
                <Typography variant="body1" className={classes.skillRatingLabel}>Anglais</Typography>
                <Rating value={4} readOnly className={classes.rating} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Scolarité
              </Typography>
            </Box>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="2007 – 2010 Polytech’ Grenoble"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Diplôme d’ingénieur en réseaux informatiques et communication multimédia.
                      <br />
                      Java EE, UX/UI, Database, bases de l&apos;informatique…
                    </Typography>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="2005 – 2007 DUT Réseau"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Diplôme universitaire technique
                    </Typography>
                  )}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ backgroundColor: 'white', borderRadius: 10 }} mt={2}>
        <Card className={classes.flat}>
          <CardContent>
            <Box height={80}>
              <Typography variant="h4">
                Centres d&apos;intérêts
              </Typography>
            </Box>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Passer du temps avec mon épouse et mes enfants"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Sports"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Musculation, randonnée et autres sports divers
                    </Typography>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Loisirs"
                  secondary={(
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Jeux de plateau, jeux video, séries, manga...
                      <br />
                      Apprendre de nouvelles choses, la culture générale, l&apos;histoire...
                    </Typography>
                  )}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default French;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ReactCountryFlag from 'react-country-flag';
import { createTheme, ThemeProvider } from '@material-ui/core';
import English from './English';
import French from './French';

const theme = createTheme();

const useStyles = makeStyles({
  app: {
    backgroundColor: '#463f32'
  },
  toggle: {
    position: 'fixed',
    display: 'block',
    right: 10,
    top: 10,
    backgroundColor: 'white',
    zIndex: 999
  }
});

function App() {
  const classes = useStyles();
  const [lang, setLang] = React.useState('en');

  const handleLanguage = (event, newLang) => {
    setLang(newLang);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.app}>
        <ToggleButtonGroup
          className={classes.toggle}
          value={lang}
          exclusive
          onChange={handleLanguage}
          aria-label="language"
        >
          <ToggleButton value="en" aria-label="english">
            <ReactCountryFlag countryCode="US" svg />
          </ToggleButton>
          <ToggleButton value="fr" aria-label="francais">
            <ReactCountryFlag countryCode="FR" svg />
          </ToggleButton>
        </ToggleButtonGroup>
        {lang === 'en' && <English />}
        {lang === 'fr' && <French />}
      </div>
    </ThemeProvider>
  );
}

export default App;
